.screen {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 5vmin;
    justify-items: center;
    align-items: center;
    justify-content: space-around;
    align-content: space-around;
    height: 100vh;

    -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 0.5s; /* Firefox < 16 */
        -ms-animation: fadein 0.5s; /* Internet Explorer */
         -o-animation: fadein 0.5s; /* Opera < 12.1 */
            animation: fadein 0.5s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.screen__actions {
    display: grid;
    grid-template-rows: 1fr;
    grid-gap: 4vmin;
    margin: 2vmin;
    justify-items: stretch;
    align-items: stretch;
    justify-content: space-around;
    align-content: space-around;
    grid-area: actions;
}

.screen__text {
    margin: 0 2em;
    overflow-y: auto;
    
    background-color: #ccc;
    padding: 1em;
    border-radius: 1em;
    font-size: 1.25em;
}

.screen--about {
    display: flex;
    flex-direction: column;
}

.screen--about .screen__text {
    margin-top: 0.5em;
}

.screen--welcome {
    grid-template-rows: 1fr 1fr;

    grid-template-areas:
        "tiles"
        "actions";
}

.screen--welcome .screen__actions {
    grid-template-columns: 2fr 2fr 2fr 2fr 1fr 1fr;
}


@media only screen and (max-width: 1600px) {
    .screen--welcome .screen__actions {
        font-size: 0.96vw;
    }
}

@media only screen and (max-width: 700px) {
    .screen--welcome .screen__actions {
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
        font-size: 1.5vw;
    }
}

@media only screen and (max-aspect-ratio: 1/1) {
    .screen--welcome .screen__actions {
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-template-columns: 1fr;
        font-size: 1.75vw;
    }
}

@media only screen and (max-aspect-ratio: 7/8) {
    .screen--welcome .screen__actions {
        font-size: 2vw;
    }
}

@media only screen and (max-aspect-ratio: 3/4) {
    .screen--welcome .screen__actions {
        font-size: 2.5vw;
    }
}

@media only screen and (max-aspect-ratio: 2/3) {
    .screen--welcome .screen__actions {
        font-size: 3vw;
    }
}


.screen--letters.screen--active {
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
        "clock"
        "tiles";
}

.screen--letters.screen--setup, .screen--letters.screen--finished {
    grid-template-areas:
        "tiles"
        "actions";
}

.screen--letters.screen .screen__actions, .screen--numbers.screen--finished .screen__actions {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
}

@media only screen and (max-aspect-ratio: 1/1) {
    .screen--letters .screen__actions, .screen--numbers.screen--finished .screen__actions {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
    }
}

.screen--numbers {
    grid-template-areas:
        "target"
        "tiles"
        "actions";
}

.screen--numbers.screen--setup {
    grid-template-rows: 2fr 3fr 3fr;
}

.screen--numbers.screen--finished {
    grid-template-rows: 2fr 3fr 2fr;
}

.screen--numbers.screen--setup .screen__actions {
    grid-template-columns: 1fr 1fr 1fr;
}

.screen--numbers.screen--active {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;

    grid-template-areas:
        "target clock"
        "tiles tiles";
}

@media only screen and (orientation: portrait) {
    .screen--numbers.screen--active {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr;

        grid-template-areas:
            "clock"
            "target"
            "tiles";
    }
}

@media only screen and (max-width: 800px) {
    .screen--numbers .screen__actions {
        font-size: 1.5vw;
    }
}

@media only screen and (max-aspect-ratio: 1/1) {
    .screen--numbers.screen--setup .screen__actions {
        grid-template-columns: 1fr 1fr;
        font-size: 2vw;
    }
}

@media only screen and (max-aspect-ratio: 2/3) {
    .screen--numbers.screen--setup .screen__actions {
        grid-template-columns: 1fr;
        font-size: 3vw;
    }
}


.screen--conundrum {
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas:
        "tiles"
        "solution"
        "actions";
}

.screen--conundrum.screen--active {
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
        "tiles tiles"
        "solution solution"
        "actions clock";
}

@media only screen and (orientation: portrait) {
    .screen--conundrum.screen--active {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr;
        grid-template-areas:
            "tiles"
            "solution"
            "clock"
            "actions";
    }
}

.screen--conundrum.screen--active .screen__actions {
    grid-template-columns: 1fr 1fr;
}

.screen--settings, .screen--editNumbers, .screen--editLetters, .screen--editConundrum {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    justify-content: space-around;
    height: initial;
    min-height: 100vh;
}

p {
    margin-top: 0;
    margin-bottom: 0.5em;
}

p:last-child {
    margin-bottom: 0;
}

* + p {
    margin-top: 1.5em;
}