.clock {
    position: relative;
    grid-area: clock;
    justify-self: stretch;
    align-self: stretch;
}

.clock__canvas {
    position: absolute;
    top: 0; left: 0;
}