.solution {
    background-color: #999;
    color: black;
    border-radius: 0.5em;
    font-size: 2em;
    
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 0 0.5em;
}

.solution__value {
    font-weight: bold;
}

.solution__working {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    white-space: nowrap;
}
