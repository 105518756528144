.settingsSection {
    flex-shrink: 0;
    display: flex;
    align-items: stretch;
    margin: 0.5em;
    max-width: 100vw;
}

.settingsSection--main {
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 950px) {
    .settingsSection--main {
        font-size: 1.65vw;
    }
}

@media only screen and (max-width: 500px) {
    .settingsSection--main {
        flex-wrap: wrap;
        margin-bottom: 3em;
        font-size: 3vw;
    }
}

@media only screen and (max-width: 750px) {
    .settingsSection--name {
        flex-direction: column;
    }
}

@media only screen and (max-width: 400px) {
    .settingsSection--name {
        font-size: 3.8vw;
    }
}

.settingsSection > button + button {
    margin-left: 1em;
}

@media only screen and (max-width: 600px) {
    .settingsSection--actions {
        flex-direction: column;
        align-items: center;
    }

    .settingsSection--actions > button + button {
        margin-top: 0.5em;
        margin-left: 0;
    }
}

.settingsSection--vertical {
    flex-direction: column;
    align-items: center;
}

.settingsSection__nameAndSelect {
    display: flex;
    flex-direction: column;
    width: 22em;
}

.settingsSection__name {
    font-size: 2em;
    font-weight: bold;
    margin-right: 0.5em;
}

.settingsSection__select, .settingsSection__input {
    font-size: 2em;
    text-align: center;
}

.settingsSection__input[type="number"] {
    max-width: 3em;
}

textarea.settingsSection__input {
    min-height: 4em;
    max-width: calc(100vw - 2em);
}

textarea.settingsSection__input--letters {
    min-height: 8em;
}

.settingsSection__input--error {
    color: #600;
    border-color: #600;
    background-color: #f88;
}

* + .settings__option {
    margin-left: 2em;
}

.settingsSection--stepList {
    align-items: stretch;
}