button {
    border: solid #008 0.2em;
    background-color: #ccc;
    color: black;
    font-weight: bold;
    border-radius: 0.5em;
    padding: 1em;
    font-size: 2em;
    cursor: pointer;
    transition: background-color 0.25s, border-color 0.25s;
}

button:focus, button:hover {
    outline: none;
    background-color: #fff;
    border-color: #00c;
}

button[disabled] {
    background-color: #999;
    border-color: #999;
    cursor: not-allowed;
}

button.button--short {
    padding: 0.5em 1em;
}