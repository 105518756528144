body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    background: linear-gradient(to bottom right, #60A0C0, #048);
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    background-attachment: fixed;
}
